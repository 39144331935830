class signUpStepsHandler {
  constructor() {
    this.container = document.querySelector(".signup-steps");
    this.bar = this.container.querySelector(".progress-bar .bar");
    this.time = 10000;
    this.timer = 0;
    this.isRunning = false;
    this.progressInt;
    this.steps = this.container.querySelectorAll(".step");
    this.index = 0;

    this.init();
  }

  init() {
    window.addEventListener("scroll", () => {
      if (this.container.getBoundingClientRect().top < 500) {
        if (this.isRunning == false) {
          this.startPresentation();
          this.isRunning = true;
        }
      }
    });
  }

  startPresentation() {
    this.progressInt = setInterval(() => this.animateBar(), 10);
  }

  animateBar() {
    if (this.timer == this.time) {
      clearInterval(this.progressInt);
    } else {
      this.steps[this.index].classList.add("checked");
      this.timer += 10;
      this.width = (this.timer / this.time) * 100;
      this.updateIndex();
      this.bar.style.width = this.width + "%";
    }
  }

  updateIndex() {
    if (this.width === 21) this.index = 1;
    if (this.width === 48) this.index = 2;
    if (this.width === 73) this.index = 3;
    if (this.width === 96) this.index = 4;
  }
}

export default signUpStepsHandler;
