const handleMobileMenu = () => {
  const container = document.querySelector(".site-header");
  const toggleBtn = container.querySelector("#toggle");
  const menu = container.querySelector(".main-navigation");

  toggleBtn.addEventListener("click", e => {
    e.preventDefault();

    toggleBtn.classList.toggle("open");
    menu.classList.toggle("open");
  });
};

export default handleMobileMenu;
