const container = document.querySelector(".page-template-library .library");

const searchBooks = input => {
  const books = container.querySelectorAll(".list .single-book");
  const value = input.value.toLowerCase();
  books.forEach(book => {
    book.classList.remove("found");
    book.classList.remove("hidden");
    const title = book.getAttribute("data-title").toLowerCase();
    const author = book.getAttribute("data-author").toLowerCase();
    if (title.includes(value) || author.includes(value)) {
      book.classList.add("found");
    } else {
      book.classList.add("hidden");
    }
  });
};

const showAllBooks = () => {
  const books = container.querySelectorAll(".list .single-book");
  books.forEach(book => {
    book.classList.remove("hidden");
  });
};

const handleLibrary = () => {
  const form = container.querySelector("#library-form");
  const input = form.querySelector("input");
  let timeout = null;

  form.addEventListener("submit", e => {
    e.preventDefault();
    // searchBooks(input);
  });
  input.addEventListener("input", () => {
    clearTimeout(timeout);
    if (input.value.length > 2) {
      timeout = setTimeout(() => {
        searchBooks(input);
      }, 1500);
    }

    if (input.value.length === 0) {
      timeout = setTimeout(() => {
        showAllBooks();
      }, 1500);
    }
  });
};

export default handleLibrary;
