import { getCoords } from "./helper";
export default class SiteHeader {
  constructor(
    defaultSettings = {
      setBodyPaddingTop: false,
      stickyHeader: true
    }
  ) {
    this.defaultSettings = defaultSettings;
    this.siteHeader = document.getElementById("masthead");
    this.init();
  }

  init() {
    this.defaultSettings.setBodyPaddingTop === false ||
      this.setBodyPaddingTop();
    this.defaultSettings.stickyHeader === false || this.stickyHeader();
    this.handleSmallMenu();
  }

  setBodyPaddingTop() {
    let $body = document.querySelector("body"),
      siteHeaderHeight = this.siteHeader.clientHeight;

    $body.style.paddingTop = `${siteHeaderHeight}px`;
  }

  stickyHeader() {
    window.addEventListener("scroll", () => {
      let headerOffsetTop = getCoords(this.siteHeader).top;

      if (headerOffsetTop > 0) this.siteHeader.classList.add("sticky");
      else this.siteHeader.classList.remove("sticky");
    });
  }

  handleSmallMenu() {
    const container = document.querySelector(".main-navigation .small-menu");
    const btn = container.querySelector("a[role='button']");
    const menu = container.querySelector(".small-menu__container");
    btn.addEventListener("click", e => {
      e.preventDefault();
      btn.classList.toggle("open");
      menu.classList.toggle("open");
    });
  }
}
