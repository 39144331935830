// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  testimonials: $(".testimonials-wrapper .inner"),
  gallery: $(".gallery-wrapper .inner"),
  trusted: $(".trusted-by-wrapper .inner")
};

sliders.testimonials.slick({
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaySpeed: 10000,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

sliders.trusted.slick({
  dots: false,
  arrows: false,
  slidesToShow: 6,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

sliders.gallery.slick({
  dots: false,
  appendArrows: $(".gallery-wrapper .left .slider-nav"),
  prevArrow:
    "<button><svg xmlns='http://www.w3.org/2000/svg' width='6' height='10' viewBox='0 0 6 10'><path id='arrow-backward' d='M5,1.809,8.783,5.78a.688.688,0,0,0,1.009,0,.783.783,0,0,0,0-1.062L5.508.218A.689.689,0,0,0,4.523.2L.208,4.714a.781.781,0,0,0,0,1.062.688.688,0,0,0,1.009,0Z' transform='translate(0 10) rotate(-90)' fill='#e53935'/></svg></button>",
  nextArrow:
    "<button><svg xmlns='http://www.w3.org/2000/svg' width='6' height='10' viewBox='0 0 6 10'><path id='arrow-forward' d='M11.189,15.438l3.781-3.971a.688.688,0,0,1,1.009,0,.783.783,0,0,1,0,1.062l-4.284,4.5a.689.689,0,0,1-.985.022L6.4,12.532a.781.781,0,0,1,0-1.062.688.688,0,0,1,1.009,0Z'transform='translate(-11.246 16.188) rotate(-90)' fill='#e53935'/></svg></button>"
});
