const handleFAQ = () => {
  const questions = [...document.querySelectorAll(".faq-question")];
  const answers = [...document.querySelectorAll(".faq-answer")];

  questions.forEach((question, index) => {
    question.addEventListener("click", () => {
      question.closest(".faq-item").classList.toggle("active");
    });
  });
};

export default handleFAQ;
