import { isMobile, numberTilesHandler, handleReadMoreLink } from "./helper";
import SiteHeader from "./siteHeader";
import FloatLabels from "./forms/float-labels";
import handleCourseSelection from "./courseSelection";
import signUpStepsHandler from "./signupStepsHandle";
import animatedNumbers from "./animatedNumber";
import handleMobileMenu from "./handleMobileMenu";
import handleLibrary from "./handleLibrary";
import handleFAQ from "./faq";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();

  if (document.querySelector(".faq-items")) {
    handleFAQ();
  }

  if (document.querySelector(".in-numbers-wrapper")) {
    numberTilesHandler();
  }

  if (document.querySelector(".price-list-container")) {
    animatedNumbers();
  }

  if (document.querySelector(".signup-steps-wrapper .signup-steps")) {
    new signUpStepsHandler();
  }

  if (document.querySelector(".course-type-selection-wrapper")) {
    new handleCourseSelection();
  }

  if (document.querySelector(".about-school-show-more-btn")) {
    handleReadMoreLink();
  }

  if (document.querySelector(".page-template-library .library")) {
    handleLibrary();
  }

  if (isMobile()) {
    handleMobileMenu();
  }
});

window.onresize = () => {
  if (isMobile()) {
  }
};
