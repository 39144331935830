class handleCourseSelection {
  constructor() {
    this.form = document.querySelector("#course-selection-form");
    this.inputs = this.form.querySelectorAll("input[type='radio']");
    this.coursesTiles = [
      ...document.querySelectorAll(".courses-list .single-course-tile")
    ];
    this.selected = {
      type: "all",
      lang: "all"
    };

    this.init();
  }

  init() {
    this.form.addEventListener("change", e => {
      let type = null;
      let lang = null;
      type = [...this.form.querySelectorAll("input[name='type']:checked")];
      lang = [...this.form.querySelectorAll("input[name='lang']:checked")];

      if (type.length > 0) {
        this.selected["type"] = type[0].id;
      }

      if (lang.length > 0) {
        this.selected["lang"] = lang[0].id;
      }

      this.filterCourses();
    });
  }

  filterCourses() {
    const selectedTiles = this.coursesTiles.filter(tile => {
      return (
        tile.classList.contains(this.selected["type"]) &&
        tile.classList.contains(this.selected["lang"])
      );
    });

    const faded = this.coursesTiles.filter(el => {
      return !selectedTiles.includes(el);
    });

    this.coursesTiles.forEach(el => {
      el.classList.remove("faded");
    });

    faded.forEach(el => {
      el.classList.add("faded");
    });
  }
}

export default handleCourseSelection;
