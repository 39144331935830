import { CountUp } from "countup.js";

const animatedNumbers = () => {
  const prices = [
    ...document.querySelectorAll(
      ".price-list-container .single-item .price span"
    )
  ];
  prices.forEach(el => {
    const countUp = new CountUp(el, el.textContent, {
      useGrouping: false
    });
    countUp.start();
  });
};

export default animatedNumbers;
